import * as React from "react"
import { Link, graphql } from "gatsby"

import Bio from "../components/bio"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { GatsbyImage, getImage } from 'gatsby-plugin-image'

const BlogIndex = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata?.title || `Title`
  const posts = data.allMarkdownRemark.nodes

  if (posts.length === 0) {
    return (
      <Layout location={location} title={siteTitle}>
        <Seo title="すべての記事" />
        <p>
          記事が投稿されていません。
        </p>
      </Layout>
    )
  }

  return (
    <Layout location={location} title={siteTitle}>
      <Seo title="すべての記事" />
      <ol style={{ listStyle: `none` }}>
        {posts.map(post => {
          const title = post.frontmatter.title || post.fields.slug
          const thumbnail = getImage(post.frontmatter.thumbnail)

          return (
            <li key={post.fields.slug}>
              <article
                className="post-list-item"
                itemScope
                itemType="http://schema.org/Article"
              >

                <header>
                  <Link to={post.fields.slug} itemProp="url">
                    < GatsbyImage
                      image={thumbnail}
                    />
                  </Link>
                  <h3>
                    <Link to={post.fields.slug} itemProp="url">

                      <span itemProp="headline">{title}</span>
                    </Link>
                  </h3>
                  <div>
                    <small>{post.frontmatter.date}</small>
                  </div>
                </header>

                <div>
                </div>
              </article>
            </li>
          )
        })}
      </ol>
      <Bio />
    </Layout>
  )
}

export default BlogIndex

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      nodes {
        excerpt
        fields {
          slug
        }
        frontmatter {
          date(formatString: "YYYY.MM.DD")
          title
          description
          thumbnail{
            childImageSharp {
              gatsbyImageData
            }
          }
          thumbnail_alt
        }
      }
    }
  }
`
